<template>
    <div class="home__location @relative @z-10 @border @border-black @text-3xl @font-bold ">
        <router-link :to="`/directory/${url}`" class="@flex @flex-col @relative @px-6 @py-6 lg:@py-24 @z-20 @bg-white @w-full @h-full">
            <p>{{ item.name }}</p>
            <span class="@text-gray-800 @text-xl">📍 {{ item.count }} indies</span>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'HomeLocations',
    props: ['item'],
    data() {
        return {
            url: encodeURIComponent(this.item.name)
        }
    },
}
</script>