<template>
    <div class="@pt-6 @pb-12 @px-6">
        <p class="@text-xl @font-bold @mb-4">show me:</p>
        <div class="@flex @flex-col lg:@flex-row @space-y-8 lg:@space-y-0 @w-full lg:@space-x-6 @items-center">
            <div class="@w-full lg:@w-6/12 @flex @flex-row @flex-wrap @items-center @justify-start xl:@justify-between">
                <div @click="filter('clickandcollect')"
                    :class="{ active: currentFilter == 'clickandcollect'}"
                    class="md:@w-auto @bg-red @px-4 @py-2 @mr-2 @mb-2 xl:@m-0 @border @border-black @cursor-pointer @transition @transition @duration-100 @flex @items-center">
                    Click and Collect
                    <!-- <svg v-show="currentFilter === 'clickandcollect'" class="@ml-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 8.41L15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41Z" fill="#fff"/>
                    </svg> -->
                </div>
                <div @click="filter('onlineshop')"
                    :class="{ active: currentFilter == 'onlineshop'}"
                    class="@bg-yellow @px-4 @py-2 @mr-2 @mb-2 xl:@m-0 @border @border-black @cursor-pointer @transition @transition @duration-100 @flex @items-center">
                    Online Shop
                </div>
                <div @click="filter('giftcard')"
                    :class="{ active: currentFilter == 'giftcard'}"
                    class="@bg-blue @px-4 @py-2 @mr-2 @mb-2 xl:@m-0 @border @border-black @cursor-pointer @transition @transition @duration-100 @flex @items-center">
                    Gift Card
                </div>
                <div @click="filter('takeaway')"
                    :class="{ active: currentFilter == 'takeaway'}"
                    class="@bg-pink @px-4 @py-2 @mr-2 @mb-2 xl:@m-0 @border @border-black @cursor-pointer @transition @transition @duration-100 @flex @items-center">
                    Takeaway
                </div>
                <div @click="filter('delivery')"
                    :class="{ active: currentFilter == 'delivery'}"
                    class="@bg-green @px-4 @py-2 @mr-2 @mb-2 xl:@m-0 @border @border-black @cursor-pointer @transition @transition @duration-100 @flex @items-center">
                    Delivery
                </div>
            </div>
            <div class="@w-full lg:@w-4/12 @flex @items-center lg:@px-6">
                <div class="@relative @w-full">
                    <svg class="search-icon @absolute @left-0 @ml-3 @w-4 @h-4" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
                        viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve"
                        width="512px" height="512px">
                        <path
                        d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                    </svg>
                    <input 
                        type="text" placeholder="Search" name="" id="" 
                        class="@bg-white @px-10 @py-2 @border @border-black @text-black @w-full focus:@outline-none @transition-color @duration-400 focus:@border-blue"
                        v-model="search" v-on:input="searchIndies" v-on:keydown.esc="clearSearch()">
                </div>
            </div>
            <div class="@w-full md:@w-3/12 lg:@w-2/12 @flex @items-center">
                <a href="https://forms.gle/nyFEUPJCSKeiNuMY8" target="_blank" class="@bg-white @border @border-black @px-4 @py-2 @mx-auto">? Suggest an edit</a>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .search-icon {
        top: 50%;
        transform: translateY(-50%);
    }
    .active {
        @apply @text-white @bg-black;
    }
</style>

<script>
export default {
    name: 'DirectoryFilter',
    methods: {
        filter(name) {
            if(name === this.currentFilter) {
                this.$parent.filter("")
                this.currentFilter = "";
            } else {
                this.$parent.filter(name);
                this.currentFilter = name;
            }
            
        },
        searchIndies: function() {
            this.$parent.filterSearch(this.search);
        },
        clearSearch() {
            this.search = "";
            this.$parent.filterSearch(this.search);
        }
    },
    data() {
        return {
            search: "",
            currentFilter: "",
            filters: [
                {
                    name: "clickandcollect",
                    label: "Click and Collect",
                    class: "@bg-red"
                },
                {
                    name: "onlineshop",
                    label: "Online Shop",
                    class: "@bg-yellow"
                },
                {
                    name: "giftcard",
                    label: "Gift Card",
                    class: "@bg-blue"
                },
                {
                    name: "delivery",
                    label: "delivery",
                    class: "@bg-green"
                }
            ]
        }
    },
    computed: {
        
    }
}
</script>