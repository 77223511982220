<template>
    <div>
        <DirectoryBanner v-bind:header="'Directory'" />
        <div class="@bg-offwhite @py-12">
            <div class="@container @mx-auto @flex @flex-col md:@flex-row @flex-wrap">
                <div v-for="indie in indies" :key="indie.name" :post="indie"
                    class="@w-full md:@w-1/2 lg:@w-1/3 @flex @flex-col @text-center @p-4">
                    <transition-group name="fade" tag="div" appear>
                        <HomeLocations v-bind:item="indie" :key="indie.name" />
                    </transition-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { parseHomeData } from '@/js/parseHomeData.js'

import DirectoryBanner from '@/components/directory/DirectoryBanner'
import HomeLocations from '@/components/home/HomeLocations'

export default {
    name: "Directory",
    components: {
        DirectoryBanner,
        HomeLocations
    },
    data() {
        return {
            indies: []
        }
    },
    mounted() {
        let gsheet_url = "https://sheets.googleapis.com/v4/spreadsheets/" + this.$googleDocId + "?key=" + this.$apiKey;

        this.axios
        .get(gsheet_url)
        .then(response => {
            this.indies = parseHomeData(response.data.sheets);
        })
    }
}
</script>
