<template>
    <div>
        <HomeBanner v-bind:total="totalIndies" v-bind:locations="totalLocations"/>
        <HomeIntro />
        <div class="@bg-yellow @py-16">
            <div class="@container @mx-auto">
                <div class="@w-full lg:@w-2/5 @mx-auto @mb-16 @text-center">
                    <h2 class="@text-4xl @font-bold @text-black @mb-4 @uppercase">Leeds Areas</h2>
                    <p>It’s more important than ever to shop locally and support independent businesses throughout this pandemic and Christmas. We will be adding more areas of Leeds, just takes time to add data. </p>
                    <p>
                        Find your area below for ways to support places near you:
                    </p>
                </div>
            </div>
            <div class="@container @mx-auto @flex @flex-wrap">
                <div v-for="indie in indies" :key="indie.name" :post="indie"
                    class="@w-full lg:@w-1/3 @flex @flex-col @text-center @p-4">
                    <transition-group name="fade" tag="div" appear>
                        <HomeLocations v-bind:item="indie" :key="indie.name" />
                    </transition-group>
                </div>
                <div class="@w-full lg:@w-1/3 @flex @flex-col @text-center @p-4">
                    <div class="home__location @relative @z-10 @border @border-black @text-3xl @font-bold ">
                        <router-link :to="'/directory'" class="@flex @flex-col @relative @py-6 lg:@p-24 @z-20 @bg-black @w-full @h-full">
                            <p class="@text-white">See All</p>
                            <span class="@text-xl @text-white">📍 {{ totalIndies }}+ indies</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <HomeGiftCard />
    </div>
</template>

<script>
import { parseHomeData, total } from '@/js/parseHomeData.js'

import HomeBanner from '@/components/home/HomeBanner'
import HomeIntro from '@/components/home/HomeIntro'
import HomeLocations from '@/components/home/HomeLocations'
import HomeGiftCard from '@/components/home/HomeGiftCard'

export default {
    name: 'Home',
    components: {
        HomeBanner,
        HomeIntro,
        HomeLocations,
        HomeGiftCard
    },
    data() {
        return {
            indies: [],
            totalIndies: "",
            totalLocations: ""
        }
    },
    mounted() {
        let gsheet_url = "https://sheets.googleapis.com/v4/spreadsheets/" + this.$googleDocId + "?key=" + this.$apiKey

        this.axios
        .get(gsheet_url)
        .then(response => {
            this.indies = parseHomeData(response.data.sheets);
            this.totalIndies = Math.round(total / 10) * 10;
            this.totalLocations = this.indies.length;
        })
    }
}
</script>
