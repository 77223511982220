<template>
    <div class="@py-20 @text-center">
        <p class="@text-gray-600 @text-3xl lg:@text-4xl">Leeds</p>
        <h1 class="@text-5xl lg:@text-6xl @capitalize">{{ title }}</h1>
    </div>
</template>

<script>
export default {
    props: ['header'],
    data() {
        return {
            title: this.header
        }
    }
}
</script>