import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import App from './App.vue'
import router from  './router'

import './assets/styles/index.scss';

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.prototype.$googleDocId = "1aysBJqHhvg6VFNYUBYtUbDdjrwgxqjyRAzMphIMU-Vo"
Vue.prototype.$apiKey = "AIzaSyCSsUFbEBosEiG9nXnmNzWCyuYtx821wT4"

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
