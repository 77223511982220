// Variables to hold the parsed data
var total;

function parseHomeData(entries) {   
    var indieList = [];
    total = 0;

    entries.forEach(function(value) {
        // Row count - offset by one to account for header in Google Sheets
        var rows = value.properties.gridProperties.rowCount - 1;
              
        // Format data
        var entry = {
            "name": value.properties.title,
            "count": rows,
        };

        total += rows;

        // Add data to list
        indieList.push(entry);
    });

    return indieList;
}

export { parseHomeData, total }