<template>
    <div>
        <div class="@container @mx-auto @flex @flex-col lg:@flex-row @items-center @space-x-8">
            <div class="@w-full lg:@w-1/2">
                <img src="@/assets/img/i-support-hero.svg" alt="">
            </div>
            <div class="@w-full lg:@w-1/2 @text-6xl @font-bold">
                <p>1 City</p>
                <p v-cloak>{{ locations }} Areas</p>
                <p ><span class="@bg-yellow @px-4">{{total}}+</span> Indies</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeBanner',
    props: ['total', 'locations']
}
</script>