import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/templates/Home'
import Directory from '@/templates/Directory'
import DirectoryList from '@/templates/DirectoryList'
import GiftCard from '@/templates/GiftCard'
import About from '@/templates/About'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/directory',
            name: 'Directory',
            component: Directory,
            meta: {
                reload: true,
            }
        },
        {
            path: '/directory/:id',
            name: 'DirectoryList',
            component: DirectoryList
        },
        {
            path: '/giftcard',
            name: 'Gift Card',
            component: GiftCard
        },
        {
            path: '/about',
            name: 'About',
            component: About
        }
    ],
    scrollBehavior () {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ x: 0, y: 0 })
            }, 500)
        })
    }
})