<template>
    <div>
        <DirectoryBanner v-bind:header="$route.params.id" />

        <div class="@bg-offwhite @py-12">
            <div class="@container @mx-auto">
                <DirectoryFilter />
            </div>
            <div class="@container @mx-auto @flex @flex-col md:@flex-row @flex-wrap">
                <div class="@w-full md:@w-1/2 lg:@w-1/3 @p-6" v-for="(indie, index) in searchedList" :key="index" :post="indie">
                    <transition-group name="fade" tag="div" appear>
                        <DirectoryListing v-bind:item="indie" :key="indie.name" />
                    </transition-group>

                </div>
                <div v-if="!searchedList.length" class="@flex @justify-center @mx-auto @-full @my-6 @text-center">
                    <p class="@text-2xl">No indies found!</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { parseSelectedData } from '@/js/parseSelectedData.js'

import DirectoryBanner from '@/components/directory/DirectoryBanner'
import DirectoryFilter from '@/components/directory/DirectoryFilter'
import DirectoryListing from '@/components/directory/DirectoryListing'

export default {
    name: 'Directory',
    components: {
        DirectoryBanner,
        DirectoryFilter,
        DirectoryListing
    },
    methods: {
        getAll() {
            let gsheet_url = "https://sheets.googleapis.com/v4/spreadsheets/" + this.$googleDocId + "/values/" + this.location + "?key=" + this.$apiKey
            this.axios
            .get(gsheet_url)
            .then(response => (
                this.indies = parseSelectedData(response.data.values)
            ))
        },
        filter(filter) {
            let gsheet_url = "https://sheets.googleapis.com/v4/spreadsheets/" + this.$googleDocId + "/values/" + this.location + "?key=" + this.$apiKey
            this.axios
            .get(gsheet_url)
            .then(response => (
                this.indies = parseSelectedData(response.data.values, filter)
            ))
        },
        filterSearch(input) {
            this.search = input;
        }
    },
    data() {
        return {
            indies: [],
            location: this.$route.params.id,
            search: ""
        }
    },
    mounted() {
        this.getAll();
    },
    computed: {
        searchedList() {
            return this.indies.filter(indie => {
                return (indie.name || '').toLowerCase().includes(this.search.toLowerCase()) ||
                    (indie.address || '').toLowerCase().includes(this.search.toLowerCase()) ||
                    (indie.website || '').toLowerCase().includes(this.search.toLowerCase())
            })
        }
    }
}
</script>
