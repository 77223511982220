// Variables to hold the parsed data
function parseSelectedData(entries, filter) {   
    var indieList = [];

    entries.forEach(function(value, index) {
        // Format data
        var entry = {
            "name": value[0],
            "email": value[1],
            "phone": value[2],
            "address": value[3],
            "giftcard": value[4],
            "clickandcollect": value[5],
            "vouchers": value[6],
            "onlineshop": value[7],
            "website": value[8],
            "social": value[9],
            "other": value[10],
            "tags": value[11],
            "takeaway": value[12],
            "delivery": value[13],
        };

        // Skip header row
        if (index > 0) {
            // Add data to list
            if (!filter) {
                indieList.push(entry);
            } else {
                if (entry[filter] === "Yes") {
                    indieList.push(entry);
                }
            }
        }
    });

    return indieList;
}

export { parseSelectedData }