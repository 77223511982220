<template>
    <nav class="@fixed @w-full @bg-white @border-b-2 @border-yellow @z-50 @p-4 lg:@p-6">
        <div class="@container @mx-auto @flex @items-center @justify-between @overflow-hidden">
            <div>
                <router-link to="/" class="@flex @items-center @space-x-4">
                    <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.0648 23H13.0251H13.0052C12.0515 22.9801 11.1574 22.5825 10.5018 21.8867C9.5878 20.8927 7.70027 19.004 6.05117 17.3142L5.93197 17.1949C3.56759 14.8093 2.57417 13.7954 2.2364 13.3779C0.42835 11.1513 -0.326667 8.54692 0.130312 6.22091C0.587292 3.8949 2.21652 1.92673 4.60076 0.833304C6.985 -0.26012 9.60767 -0.240235 11.6541 0.912831C12.0316 1.13152 12.5085 1.4496 12.9853 1.86709C13.4622 1.4496 13.939 1.11163 14.3165 0.892943C16.3431 -0.260123 18.9857 -0.299884 21.3699 0.793541C23.7542 1.86708 25.3834 3.83525 25.8602 6.16126C26.3371 8.48727 25.5821 11.0916 23.774 13.3182C23.2972 13.9146 20.5156 16.8172 15.5087 21.9265C14.8729 22.6223 13.9986 23 13.0648 23ZM13.0449 19.8191C13.1244 19.8191 13.184 19.7993 13.2436 19.7396C20.1381 12.6622 21.1514 11.529 21.2904 11.3501C22.4826 9.85903 23.019 8.20896 22.7409 6.81733C22.4627 5.48534 21.4891 4.35215 20.0586 3.69609C18.5883 3.04004 17.0385 3.02015 15.8862 3.67621C15.5484 3.87501 14.7934 4.43167 14.2371 5.14736C13.939 5.52509 13.4821 5.74378 13.0052 5.74378C12.5284 5.74378 12.0714 5.52509 11.7535 5.14736C11.1972 4.45155 10.4422 3.87502 10.1044 3.69609C8.952 3.04004 7.40225 3.05992 5.93197 3.73586C4.50142 4.39191 3.50799 5.54497 3.2497 6.85708C2.97153 8.24871 3.50799 9.8988 4.71998 11.3898C4.99814 11.7278 6.62737 13.3779 8.197 14.9683L8.31621 15.0876C10.005 16.7774 11.8926 18.7058 12.8463 19.7396C12.9059 19.7794 12.9853 19.8191 13.0449 19.8191Z" fill="#25293C"/>
                    </svg>
                    <p class="@font-bold @hidden md:@block">#BUYLEEDS</p>
                </router-link>
            </div>
            <div class="@block md:@hidden">
                <button @click="isOpen=!isOpen" :class="[isOpen ? '@text-yellow' : '@text-black']"
                    class="@flex @items-center @px-3 @py-2 @border @rounded @border-none focus:@outline-none">
                    <svg class="@fill-current @h-6 @w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                    </svg>
                </button>
            </div>
            <div class="@space-x-6 @hidden md:@flex">
                <router-link to="/directory">Directory</router-link>
                <router-link to="/about">About</router-link>
                <router-link to="/giftcard">Gift Card</router-link>
                <a target="_blank" href="https://kirkstallbrewery.com/collections/leeds-indie-food">Buy Beer</a>
                <a target="_blank" href="https://leedsindiefood.awesomedistro.com/">Shop</a>
            </div>
        </div>
        <transition name="slide-fade">
            <div v-show="isOpen" class="@absolute @left-0 @mt-4 @space-y-3 @bg-white @w-full md:@hidden @p-6 @flex @flex-col @bg-yellow">
                <router-link to="/directory" @click.native="isOpen=!isOpen">Directory</router-link>
                <router-link to="/about" @click.native="isOpen=!isOpen">About</router-link>
                <router-link to="/giftcard" @click.native="isOpen=!isOpen">Gift Card</router-link>
                <a target="_blank" href="https://kirkstallbrewery.com/collections/leeds-indie-food">Buy Beer</a>
                <a target="_blank" href="https://leedsindiefood.awesomedistro.com/">Shop</a>
            </div>
        </transition>
    </nav>
</template>

<style scoped>
    a {
        @apply font-bold
    }
</style>

<script>
export default {
    name: 'Header',
    data() {
        return {
            isOpen: false
        }
    }
}
</script>