<template>
  <div id="app">
    <Header />
    <transition name="fade" mode="out-in">
      <router-view class="@pt-16"></router-view>
    </transition>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  created () {
    document.title = "Indie Directory";
  }
};
</script>
